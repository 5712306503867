/* eslint-disable import/prefer-default-export */

export const BASENAME = process.env.REACT_APP_BASEPATH || '/waiting-list';
export const HOSTNAME = process.env.REACT_APP_HOSTNAME || 'cl-cards.com';

export const links = {
  baanx: 'http://baanx.com/',
  digital_banking: 'https://www.baanx.com/services',
  payments: 'https://www.baanx.com/services',
  compliance: 'https://www.baanx.com/services',
  digital_assets: 'https://www.baanx.com/services',
  about_us: 'https://www.baanx.com/about-us',
  careers: 'https://www.baanx.com/careers',
  newsletter: 'https://www.baanx.com/newsletter',
  blog: 'https://www.baanx.com/blog',
  working_with_us: 'https://www.baanx.com/contact-us',
  linkedin: 'https://www.linkedin.com/company/baanxgroup/',
  twitter: 'https://twitter.com/BaanxGroup',
  facebook: 'https://www.facebook.com/baanxgroup',
  privacy: 'https://baanx.com/privacy-policy',
  terms: 'https://baanx.com/terms-of-use',
  services: 'https://www.baanx.com/services',
  company: 'https://www.linkedin.com/company/baanxgroup/',
  contact: 'https://www.baanx.com/contact-us',
  resources: 'https://www.baanx.com/api-documentation',
  documentation: '#',
  tc: `${BASENAME}/terms-of-use`,
  PrivacyPolicy: `${BASENAME}/privacy-policy`,
  MasterCardTerms: `${BASENAME}/mastercard-uk-terms`,
  OptimusCardPrivacyPolicy: `${BASENAME}/optimus-privacy`,
};
