import React from 'react';
import { links } from '../../../../utils/constants';
import { SectionContainer } from '../Layout';

const Header = () => (
  <>
    <SectionContainer className="bg-dark">
      <div className="wl-header py-4">
        <a href={links.baanx} target="_blank" rel="noreferrer">
          <img src="/assets/images/logo.svg" alt="Ledger" />
        </a>
        <div>
          <button
            className="btn btn-responsive"
            type="button"
            data-toggle="modal"
            data-target="#modal">
            Join waitlist
          </button>
        </div>
      </div>
    </SectionContainer>
    <div className="wl-header-hr" />
  </>
);

export default Header;
