/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';

export default ({ style }) => (
  <svg
    style={style}
    className="wl-footer-icon"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0039 10.8848C20.0039 5.36191 15.5268 0.884766 10.0039 0.884766C4.48105 0.884766 0.00390625 5.36191 0.00390625 10.8848C0.00390625 15.876 3.66074 20.0131 8.44141 20.7633V13.7754H5.90234V10.8848H8.44141V8.68164C8.44141 6.17539 9.93437 4.79102 12.2186 4.79102C13.3123 4.79102 14.457 4.98633 14.457 4.98633V7.44727H13.1961C11.9539 7.44727 11.5664 8.21816 11.5664 9.00977V10.8848H14.3398L13.8965 13.7754H11.5664V20.7633C16.3471 20.0131 20.0039 15.876 20.0039 10.8848Z"
      fill="white"
    />
  </svg>
);
