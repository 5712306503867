import React from 'react';
import { isMobile } from 'react-device-detect';
import Fade from 'react-reveal/Fade';

import { SectionContainer } from '../Layout';

const Note = () => (
  <SectionContainer>
    <div
      className={`note-layout ${isMobile ? '' : 'mx-auto'}`}
      style={{
        marginTop: isMobile ? '5rem' : '6.5rem',
        marginBottom: isMobile ? '5rem' : '6.5rem',
      }}>
      <Fade>
        <p className="fs-f5 mb-5">
          *Subject to eligibility conditions, including country of residence and availability.
          Entering the waiting list does not mean you will receive a CL Card. It does not in any
          event constitute a commitment to deliver the CL Card or a commitment on the features that
          will be offered by the CL Card. The CL Card and its features are provided to you solely by
          Baanx Group Ltd, UK, Baanx US Corp, United States and Frozen Time UNIPESSOAL LDA, EU and
          not by Ledger. By filling out this form, you consent to Baanx collecting your information.
          Baanx Group Ltd, UK, Baanx US Corp, United States and Frozen Time UNIPESSOAL LDA, EU will
          add you to the waiting list and transfer your data to Ledger which will send you news and
          updates about the CL card.
        </p>
        <p className="fs-f5 mb-5">
          *The CL Card and its features are provided by Baanx Group Ltd (in the UK) Baanx US Corp
          (in the United States) and Frozen Time UNIPESSOAL LDA (in the EU).
        </p>
      </Fade>
    </div>
  </SectionContainer>
);

export default Note;
