/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';

export default ({ style }) => (
  <svg
    style={style}
    className="wl-footer-icon"
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.2957 17.1354C13.841 17.1354 17.9691 10.8826 17.9691 5.46193C17.9691 5.28615 17.9652 5.10646 17.9574 4.93068C18.7605 4.34993 19.4535 3.63059 20.0039 2.80646C19.256 3.13921 18.4619 3.35653 17.6488 3.45099C18.505 2.9378 19.146 2.1316 19.4531 1.18185C18.6477 1.65917 17.7669 1.99589 16.8484 2.17755C16.2296 1.52001 15.4114 1.08464 14.5203 0.938752C13.6292 0.792864 12.7149 0.944585 11.9187 1.37046C11.1225 1.79633 10.4887 2.47263 10.1154 3.2948C9.74215 4.11697 9.6501 5.03922 9.85351 5.91896C8.22265 5.83712 6.62719 5.41346 5.17056 4.67546C3.71393 3.93745 2.42865 2.90157 1.39805 1.63497C0.874239 2.53808 0.713953 3.60675 0.949765 4.62379C1.18558 5.64082 1.79979 6.52992 2.66758 7.11036C2.0161 7.08968 1.37889 6.91428 0.808594 6.59864V6.64943C0.80801 7.59717 1.13565 8.51586 1.73583 9.24935C2.33601 9.98284 3.17168 10.4858 4.10078 10.6729C3.49729 10.838 2.86389 10.862 2.24961 10.7432C2.51179 11.5582 3.02188 12.2711 3.70872 12.7823C4.39555 13.2935 5.22484 13.5775 6.08086 13.5947C4.62759 14.7363 2.83238 15.3555 0.984375 15.3526C0.656646 15.3521 0.329239 15.332 0.00390625 15.2924C1.88129 16.4968 4.06518 17.1365 6.2957 17.1354Z"
      fill="white"
    />
  </svg>
);
